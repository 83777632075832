import { config } from "../config.js";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/no-empty-object-type
  interface Window extends Record<string, unknown> {}
}

/**
 * Collection of helpers that make it easier to connect with the Google Apis
 * that are required in order for the backend to be able to export files to
 * Google Drive.
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GoogleApi {
  // keep track of authentication requests and their status in an internal map
  // by using state in the request, so that we can fake-resolve auth requests
  // once we get a respons for a specific request
  //
  // this is a bit hack'ish, but is needed in order to be able to allow the
  // authorize method to return a promise which resolves once the user has been
  // signed in
  let autoIncrementedAuthRequestId = 0;
  const authRequestCallbacks = new Map<
    number,
    {
      resolve: (accessToken: string) => void;
      reject: (reason: string) => void;
    }
  >();

  /**
   * Keeps track of whether or not the user is currently signed in to and has
   * authorized access to his Google account!
   */
  let currentAccessToken: string | undefined = undefined;

  /**
   * Contains a list of names of Google Apis that have already been loaded for
   * the current page!
   */
  const loadedApis = new Set<string>();

  /**
   * Helper that'll bind a listener to the initial authorization callback,
   * which is an easy way for third party applications to know when usage of
   * the GoogleApi is ready.
   */
  export function onInitialAuthorization(callback: () => void): void {
    // the new Google Identity Services does not expose a SSO-style automatic
    // login, so always just trigger callbacks immediately
    callback();
  }

  /** Helper that attempts to authorize access to the user's Google Account */
  export async function authorize(
    onSuccess?: () => void,
    onError?: () => void,
  ): Promise<void> {
    const promise = new Promise<string>((resolve, reject) => {
      try {
        const requestId = ++autoIncrementedAuthRequestId;
        const left = Math.round((screen.availWidth - 500) / 2);
        const top = Math.round((screen.availHeight - 700) / 2);

        const authWindow = window.open(
          `${config.googleApi.authUrl}?state=${requestId}`,
          "gexport_google_oauth",
          `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,width=500,height=550,top=${top},left=${left}`,
        );

        // cache callbacks to resolve/reject the external promise
        authRequestCallbacks.set(requestId, { resolve, reject });

        if (!authWindow) {
          reject("Could not open auth window");
          return;
        }

        const resolveInterval = window.setInterval(() => {
          if (!authWindow.closed) {
            // wait until the auth window has been opened
            return;
          }

          window.clearInterval(resolveInterval);

          // wait for a bit before rejecting, to ensure that any .postMessage
          // events are handled first
          setTimeout(() => {
            reject("Could not receive response from Sign In flow");
          }, 50);
        }, 200);
      } catch (err) {
        reject(err);

        // Trigger the error callback (exists purely for
        // backwards compatibility)
        if (onError) {
          onError();
        }
      }
    }).then(
      (newAccessToken) => {
        currentAccessToken = String(newAccessToken);
        onSuccess?.();
      },
      (reason) => {
        console.error("gExport.GoogleApi.authorize(): " + reason);
        onError?.();

        return Promise.reject();
      },
    );

    return promise;
  }

  export function isAuthorized(): boolean {
    return currentAccessToken !== undefined;
  }

  /** Helper that gets the access token of the currently signed in user */
  export async function getAccessToken(): Promise<string> {
    return currentAccessToken ?? "";
  }

  /** Specification of the response format for the getUserData helper */
  export type UserData = {
    id: string;
    mail: string;
    name: string;
  };

  /** Helper that fetches data about the currently signed in user */
  export async function getUserData(
    callback?: (data: UserData | { error: Error }) => void,
  ): Promise<UserData> {
    try {
      // Bail out if the user has not been authorized!
      if (currentAccessToken === undefined) {
        throw new Error(
          "gExport.GoogleApi.getUserData(): User must be authorized!",
        );
      }

      // step 1: OpenID Connect disovery to fetch userinfo endpoint
      const discoveryRes = await fetch(
        "https://accounts.google.com/.well-known/openid-configuration",
      );
      const discoveryJson = await discoveryRes.json();

      // step 2: Fetch userinfo through OpenID Connect
      const openidRes = await fetch(discoveryJson.userinfo_endpoint, {
        headers: {
          Authorization: `Bearer ${currentAccessToken}`,
        },
      });
      const openidJson = await openidRes.json();

      // Submit the request to Google Drive
      const output = {
        id: openidJson.sub,
        name: openidJson.name,
        mail: openidJson.email,
      };

      // Trigger callback if given (exists purely for backwards
      // compatibility)
      if (callback) {
        callback(output);
      }

      // Resolve external promise
      return output;
    } catch (err) {
      // Trigger callback if given (exists purely for backwards
      // compatibility)
      if (callback) {
        callback({ error: err as Error });
      }

      // Reject external promise in case any errors occurred
      throw err;
    }
  }

  /**
   * Specification of the response format when accessing a specific file's
   * data.
   */
  export type FileData = {
    createdAt: string;
    fileId: string;
    fileName: string;
    folderId: string;
    modifiedAt: string;
    url: string;
    explicitlyTrashed?: boolean;
  };

  /** Helper that fetches data about a single file */
  export async function getFileData(
    fileId: string,
    callback?: (value: FileData | { error: Error }) => void,
  ): Promise<FileData> {
    try {
      // Bail out if the user has not been authorized!
      if (currentAccessToken === undefined) {
        throw new Error(
          "gExport.GoogleApi.getFileData(): User must be authorized!",
        );
      }

      await ensureGoogleApi("picker");

      // Submit the request to Google Drive
      const response = await gapi.client.request({
        method: "GET",
        params: {
          key: config.googleApi.apiKey,
        },
        path: `/drive/v2/files/${fileId}`,
      });

      // Parse the JSON-response
      const parsedResponse = response.result as {
        createdDate: string;
        defaultOpenWithLink: string;
        explicitlyTrashed: boolean;
        modifiedDate: string;
        originalFilename: string;
        parents: Array<{ id: string; isRoot: boolean }>;
      };

      // Create well-formatted file data object
      const fileData: FileData = {
        createdAt: parsedResponse.createdDate,
        explicitlyTrashed: parsedResponse.explicitlyTrashed,
        fileId,
        fileName: parsedResponse.originalFilename,
        folderId:
          parsedResponse.parents[0] && !parsedResponse.parents[0].isRoot
            ? parsedResponse.parents[0].id
            : "root",
        modifiedAt: parsedResponse.modifiedDate,
        url: parsedResponse.defaultOpenWithLink,
      };

      callback?.(fileData);
      return fileData;
    } catch (err) {
      callback?.({ error: err as Error });
      throw err;
    }
  }

  /** Internal specification of the value returned by the openPicker method */
  export type PickerResponse = {
    action: string;
    docs: Array<{ id: string }>;
  };

  /**
   * Helper that opens the Google Drive file picker, from which the user can
   * choose a position where he wants to upload his file to.
   */
  export async function openPicker(
    callback?: (value: PickerResponse) => void,
  ): Promise<PickerResponse> {
    await ensureGoogleApi("picker");

    // If the user has not been authorized yet, then do it now!
    if (currentAccessToken === undefined) {
      await authorize();
    }

    const accessToken = currentAccessToken;

    if (!accessToken) {
      throw new Error(
        "gExport.GoogleApi.openPicker(): unable to authorize user",
      );
    }

    return new Promise<PickerResponse>((resolve, reject) => {
      // Create a view that allows the user to pick a folder
      const view = new google.picker.DocsView() as google.picker.DocsView & {
        setMimeTypes(value: string): google.picker.DocsView;
      };

      // Make sure that the user sees and can select folders
      view.setIncludeFolders(true);
      view.setSelectFolderEnabled(true);

      // Only display folders owned by the user himself
      view.setOwnedByMe(true);
      view.setMimeTypes("application/vnd.google-apps.folder");

      // Display in list-form
      view.setMode(google.picker.DocsViewMode.LIST);

      // Create a view that allows the user to pick a folder
      const pickerBuilder = new google.picker.PickerBuilder()
        // Configure display
        .addView(view)
        .hideTitleBar()
        .setLocale("da-DK")
        .enableFeature(google.picker.Feature.NAV_HIDDEN)

        // Setup authentication
        .setAppId(config.googleApi.appId)
        .setOrigin(`${window.location.protocol}//${window.location.host}`)
        .setOAuthToken(accessToken)

        // Handle callback
        .setCallback((data: PickerResponse) => {
          switch (data.action) {
            // If the user cancelled, then reject the external
            // promise
            case google.picker.Action.CANCEL:
              reject(data);

              // Trigger the callback
              if (callback) {
                callback(data);
              }
              break;

            // If the user picked a file, then resolve the external
            // promise
            case google.picker.Action.PICKED:
              resolve(data);

              // Trigger the callback
              if (callback) {
                callback(data);
              }
              break;

            default:
            // There a no other cases to be handled
          }
        });

      // Render the picker
      pickerBuilder.build().setVisible(true);
    });
  }

  /**
   * Use this method to sign the currently logged in user out of his Google
   * Account!
   */
  export async function signOut(): Promise<void> {
    // Reset internal status
    currentAccessToken = undefined;
  }

  /**
   * Helper that ensures that the required Google Apis are loaded on the
   * webpack through use of the gapi client.
   */
  async function ensureGoogleApi(apiName?: string): Promise<void> {
    // If we're simply supposed to verify the existence of gapi, then
    // do so...
    if (!apiName) {
      return new Promise<void>((resolve) => {
        // Safely determine if the gapi has been loaded on the current
        // page, and if not, then wait for it to become so!
        if (
          !(window as { gapi?: unknown }).gapi ||
          !gapi.auth ||
          !gapi.client
        ) {
          // Create a unique name for the onload-callback (we'll need
          // to export this method publicly, so it cannot under any
          // circumstances be allowed to collide with existing
          // variable names)
          const onLoadCallbackId =
            `gExport_GoogleApi_OnLoad_${Date.now()}` as const;

          // Create the globally exposed callback
          window[onLoadCallbackId] = resolve;

          // Now create a script instance, that'll load the gapi onto
          // the current page!
          const script = document.createElement("script");

          script.type = "text/javascript";
          script.src = `https://apis.google.com/js/client.js?onload=${encodeURIComponent(
            onLoadCallbackId,
          )}`;

          // Inject the script into the dom to begin loading data...
          (
            document.getElementsByTagName("head")[0] || document.body
          ).appendChild(script);
        } else {
          // If the APIs are available, then immediately resolve the
          // promise as we are ready to proceed
          resolve();
        }
      });
    }

    await ensureGoogleApi();

    return new Promise((resolve) => {
      // If this api has already been loaded, then abort further
      // execution... The API is already ready!
      if (loadedApis.has(apiName)) {
        resolve();

        return;
      }

      // ... And then actually load the api!
      gapi.load(apiName, () => {
        // Register that the api has been loaded
        loadedApis.add(apiName);

        // Resolve the external promise
        resolve();
      });
    });
  }

  // handle postMessage callbacks which inform us of failure / success when
  // trying to authorize users
  window.addEventListener("message", (evt) => {
    if (
      evt.data == null ||
      typeof evt.data !== "object" ||
      !("payload" in evt.data)
    ) {
      return;
    }

    switch (evt.data["action"]) {
      case "gExport.GoogleApi.auth.success":
        authRequestCallbacks
          .get(parseInt(evt.data["state"], 10))
          ?.resolve(evt.data["payload"]);
        break;

      case "gExport.GoogleApi.auth.error":
        authRequestCallbacks
          .get(parseInt(evt.data["state"], 10))
          ?.reject(evt.data["payload"]);
        break;
    }
  });
}
