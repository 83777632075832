import { resolveUrl } from "./utils/dom.resolve-url.js";

const baseUrl = getBaseUrl();

/**
 * Contains build-time configurations of the application.
 */
let config: {
  baseUrl: string;

  /** Configurations required when interacting with the Google API */
  googleApi: {
    authUrl: string;
    apiKey: string;
    appId: string;
  };

  /** Configurations related to the usage of the LoginConnector */
  loginConnector: {
    appId: string;
    baseUrl: string;
  };

  /** Configurations required when interacting with the MS Graph API */
  microsoftApi: {
    appId: string;
    redirectUrl: string;
  };
};

switch (process.env["APP_ENV"]) {
  case "production":
    config = {
      baseUrl,

      googleApi: {
        authUrl: `${baseUrl}/google/oauth/start`,
        apiKey: "AIzaSyAft1uKSCTCtzcGpqf1ndwJgwTUhvH3jsI",
        appId: "940219589941",
      },

      loginConnector: {
        appId: "gu-all",
        baseUrl: "https://loginconnector.gyldendal.dk",
      },

      microsoftApi: {
        appId: "a9ecdbb4-45b2-484a-a996-fd997bc44a24",
        redirectUrl: `${baseUrl}/msgraph/oauth/callback`,
      },
    };
    break;

  case "staging":
    config = {
      baseUrl,

      googleApi: {
        authUrl: `${baseUrl}/google/oauth/start`,
        apiKey: "AIzaSyAft1uKSCTCtzcGpqf1ndwJgwTUhvH3jsI",
        appId: "940219589941",
      },

      loginConnector: {
        appId: "gu-all",
        baseUrl: "https://qa-loginconnector.gyldendal.dk",
      },

      microsoftApi: {
        appId: "0defe686-8203-4af5-9a42-dc522d110e3c",
        redirectUrl: `${baseUrl}/msgraph/oauth/callback`,
      },
    };
    break;

  case "test":
    config = {
      baseUrl,

      googleApi: {
        authUrl: `${baseUrl}/google/oauth/start`,
        apiKey: "AIzaSyAft1uKSCTCtzcGpqf1ndwJgwTUhvH3jsI",
        appId: "940219589941",
      },

      loginConnector: {
        appId: "gu-all",
        baseUrl: "https://test-loginconnector.gyldendal.dk",
      },

      microsoftApi: {
        appId: "9e1bde23-3a64-4b42-a8ec-efaec163454b",
        redirectUrl: `${baseUrl}/msgraph/oauth/callback`,
      },
    };
    break;

  default:
    config = {
      baseUrl,

      googleApi: {
        authUrl: `${baseUrl}/google/oauth/start`,
        apiKey: "AIzaSyAft1uKSCTCtzcGpqf1ndwJgwTUhvH3jsI",
        appId: "940219589941",
      },

      loginConnector: {
        appId: "gu-all",
        baseUrl: "https://test-loginconnector.gyldendal.dk",
      },

      microsoftApi: {
        appId: "9e1bde23-3a64-4b42-a8ec-efaec163454b",
        redirectUrl: `${baseUrl}/msgraph/oauth/callback`,
      },
    };
}

/**
 * utility to extract the base url of the ExportService based on the path from
 * which the gExport.js library was loaded
 */
function getBaseUrl(): string {
  if (process.env["NODE_ENV"] === "development") {
    return "http://localhost:5002";
  }

  // iterate over all scripts embedded on the current page, and attempt to
  // identify the one that loaded the gExport library
  for (const script of Array.from(document.getElementsByTagName("script"))) {
    const scriptUrl = script.getAttribute("src")?.split("?")[0]?.split("#")[0];

    if (!scriptUrl) {
      continue;
    }

    if (
      scriptUrl.endsWith("/gExport.js") ||
      scriptUrl.endsWith("/gExport.min.js")
    ) {
      return resolveUrl(scriptUrl)
        .replace(/\/gExport(\.min)?\.js$/i, "")
        .replace("localhost:8080", "localhost:5002");
    }
  }

  throw new Error("gExport.config: Couldn't determine baseUrl automatically!");
}

export { config };
