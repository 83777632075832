/**
 * Helper that opens a popup window and then returns a promise, which is going
 * to be resolved once that window is closed again.
 */
export async function openModalWindow(
  popupUrl: string,
  popupSize: { width: number; height: number },
  extraWindowFeatures = "",
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    // Calculate window size, so that we know how to center the
    // popup around this window
    const winWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const winHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    // Calculate popup position
    const popupLeft = (winWidth - popupSize.width) / 2 + window.screenX;
    const popupTop = (winHeight - popupSize.height) / 2 + window.screenY;

    // Prepare configurations for the popup window
    const windowSize = [
      `width=${popupSize.width}`,
      `height=${popupSize.height}`,
      `top=${popupTop}`,
      `left=${popupLeft}`,
    ].join(",");

    // Now open the window, allowing the user to signin
    const popupWindow = window.open(
      popupUrl,
      "_blank",
      `${windowSize}${extraWindowFeatures}`,
    );

    // If the window failed to open, then reject the promise!
    if (!popupWindow) {
      reject(new Error("Couldn't open modal window."));

      // Abort further execution for now
      return;
    }

    // Force the popupWindow to be in front of the current window
    popupWindow.focus();

    // Create a poll that'll check when the user closes the popup window,
    // and then make sure that external promise is resolved
    const popupPollInterval = setInterval(() => {
      if (popupWindow.closed) {
        // Stop the popup poll interval from running
        clearInterval(popupPollInterval);

        // Resolve the external promise
        setTimeout(() => {
          resolve();
        }, 1);
      }
    }, 1);
  });
}
