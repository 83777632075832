/**
 * Helper that uses the browser to resolve urls relatively to the currently
 * active address.
 */
export function resolveUrl(url: string): string {
  const resolver = document.createElement("a");
  resolver.href = url;

  return resolver.href;
}
