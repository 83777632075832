import { config } from "../config.js";
import { MicrosoftApi } from "../typings.js";
import { extractHtmlFromWindow } from "../utils/dom.extract-html-from-window.js";
import {
  runExportService,
  type ResponseStream,
} from "../utils/response-stream.js";

import type { ConvertArgs } from "./convert.js";

/** Specification of response from the OneDrive process */
export type OneDriveProcessResponse = {
  data: {
    /** An absolute link, from which the file can be opened */
    url: string;

    /** An id of the created file */
    id: string;
  };
};

/** Specification of arguments when uploading a file to OneDrive */
export type OneDriveArgs = {
  /** Optionally specify an id of a specific file to overwrite */
  fileId?: string;

  /** Optionally specify which folder to upload the file to */
  folderId?: string;
} & Omit<ConvertArgs, "cache" | "cacheKey">;

/**
 * Helper that'll upload the specified input and store it at the user's
 * OneDrive account.
 */
export function oneDrive({
  input,
  transformInput,
  ...args
}: OneDriveArgs): ResponseStream<OneDriveProcessResponse> {
  //
  // STEP: extract the output that needs to be converted and uploaded to the
  // user's Drive
  //
  const extractedInput = (() => {
    if (typeof input === "string") {
      return input;
    }
    args.baseUrl =
      input instanceof Window ? input.location.href : window.location.href;

    return input instanceof Window
      ? extractHtmlFromWindow(input)
      : extractHtmlFromWindow(window, input);
  })();

  const transformedInput = transformInput?.(extractedInput) ?? extractedInput;

  //
  // STEP: authorize the user, so that we can propagate the required upload
  // token to the user's drive
  //
  return runExportService(
    (async () => {
      // If the user has not authorized access to his Google Account yet, then
      // prompt him to do so now!
      if (!MicrosoftApi.isAuthorized()) {
        await MicrosoftApi.authorize();
      }

      // If no explicit location has been specified, then prompt the user to
      // select one now!
      if (!args.folderId && !args.fileId) {
        const pickerResponse = await MicrosoftApi.openPicker();

        args.folderId = pickerResponse.docs[0]?.id;
      }

      return new Request(`${config.baseUrl}/export/onedrive`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-Security-Method": !config.baseUrl.includes("localhost")
            ? "amazon.export.gyldendal.dk"
            : "",
        },
        body: JSON.stringify({
          ...args,
          input: transformedInput,
          access_token: await MicrosoftApi.getAccessToken(),
        }),
      });
    })(),
  );
}
