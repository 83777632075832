import { GoogleApi } from "./apis/google.js";
import { MicrosoftApi } from "./apis/microsoft.js";
import { convert } from "./methods/convert.js";
import { googleDrive } from "./methods/google-drive.js";
import { oneDrive } from "./methods/one-drive.js";
import { extractHtmlFromWindow } from "./utils/dom.extract-html-from-window.js";

window.gExport = {
  convert,
  googleDrive,
  oneDrive,

  GoogleApi,
  MicrosoftApi,

  utils: {
    extractWindowHtml: extractHtmlFromWindow,
  },
} as gExport;
