/** Explicitly type the optional onbeforeprint and onafterprint events */
declare let window: {
  onbeforeprint?(): void;
  onafterprint?(): void;
};

/**
 * Specification of internal stylesheet definitions used to identify and replace
 * external sheets with css content before submitting requests to the export
 * service backend.
 */
type StylesheetDefinition = {
  /** The actual text used */
  cssText: string;

  /** The type of the stylesheet (99% of the time simply text/css) */
  cssType: string;

  /**
   * The unique id created for this stylesheet, used for easy identification
   * and swapping.
   */
  id: string;
};

/**
 * Internal auto incremented value, used to create unique ids for each
 * stylesheet available on a document.
 */
let autoIncrementedStylesheetId = 0;

/**
 * Utility that'll extract the rendered html content from a window-object.
 */
export function extractHtmlFromWindow(
  win: Window,
  targetElement?: Element,
): string {
  // Create a document object, which we can safely manipulate without actually
  // interacting with the "real" window
  const doc = document.implementation.createHTMLDocument("clone");

  // Trigger "onbeforeprint"-event to simulate the print-event that would have
  // occurred if choosing to print the page
  if (typeof window.onbeforeprint === "function") {
    try {
      window.onbeforeprint();
    } catch (err) {
      console.error(err);
    }
  }

  // Extract CSS-code from all stylesheets found within the current document
  const stylesheets = extractStylesheets(win.document);

  // Clone the html from the live dom into our shadow document
  doc.documentElement.innerHTML = win.document.documentElement.innerHTML;

  // Apply all required attributes onto the HTML-tag
  for (const attr of Array.from(win.document.documentElement.attributes)) {
    doc.documentElement.setAttribute(attr.name, attr.value);
  }

  // Now reset the internal CSS-tags used to identify the extracted
  // stylesheets on the original document to prevent tainting it
  resetStylesheets(win.document);

  // Trigger the "onafterprint"-event to properly reset the rendered html of
  // the live dom
  if (typeof window.onafterprint === "function") {
    try {
      window.onafterprint();
    } catch (err) {
      console.error(err);
    }
  }

  // Imprint static copies of dynamic stylesheets, to ensure that the css is
  // going to be transferred directly to the ExportService backend
  imprintStylesheets(doc, stylesheets);

  // Loop through all inputs from the original window, and inject the values
  // that have been entered into them, and inject it explicitly as a value-
  // prop
  imprintInputValues(doc, win);

  // Before submitting the rendered html, we'll need to ensure that any
  // currently rendered canvas elements are translated into static images (as
  // the canvas cannot be transferred as plain html)
  rasterizeCanvasses(doc, win);

  // Strip all elements outside the desired element
  if (targetElement !== undefined) {
    for (const node of Array.from(doc.body.children)) {
      switch (node.tagName.toLowerCase()) {
        case "script":
        case "style":
        case "link":
          // leave script, style and link tags "as is"
          break;

        default:
          node.remove();
      }
    }

    doc.body.appendChild(targetElement.cloneNode(true));
  }

  // Remove all unsupported elements from the document (there is no reason to
  // transfer these to the ExportService, they will only cause problems
  // anyway)
  removeUnsupportedElements(doc);

  // Automatically inject the gExport classname
  if (!doc.documentElement.classList.contains("gExport")) {
    doc.documentElement.classList.add("gExport");
  }

  // Extract the full html from the document element
  let staticHtml = doc.documentElement.outerHTML;

  // Wrap in <html>-tag?
  if (staticHtml.indexOf("<html") === -1) {
    // Wrap in <body>-tag?
    if (staticHtml.indexOf("<body") === -1) {
      staticHtml = `<body style="background-color:#fff;>${staticHtml}</body>`;
    }

    staticHtml = `<html>${staticHtml}</html>`;
  }

  return `${extractDocType(win)}${staticHtml}`;
}

/**
 * Helper that'll return the content of all stylesheets within the current
 * document, and make sure that they're all tagged for easy identification
 * later on!
 */
function extractStylesheets(doc: Document): StylesheetDefinition[] {
  return Array.from(doc.styleSheets)
    .map((stylesheet) => {
      try {
        // Skip anything that's not a CSS stylesheet
        if (!isCssStylesheet(stylesheet)) {
          return;
        }

        // Skip stylesheets where we cannot identify the parent node
        if (!stylesheet.ownerNode || !stylesheet.ownerNode.parentNode) {
          return;
        }

        // Bail out if we're unable to extract css rules  from the
        // stylesheet
        if (!stylesheet.cssRules) {
          return;
        }

        // Generate a unique id for this stylesheet
        const id = `g${++autoIncrementedStylesheetId}`;

        // Extract css code from the stylesheet
        const cssText = extractCssText(stylesheet, doc);

        // Inject the custom id on the ownerNode, so that we can easily swap
        // it in the document clone later on
        (stylesheet.ownerNode as HTMLElement).setAttribute("gexport-id", id);

        // Return definition of this stylesheet, so that we can imprint it
        // in the document clone later on
        return {
          cssText,
          cssType: stylesheet.type,
          id,
        };
      } catch (err) {
        // If an error occurs here, it usually just means that some kind of
        // security error was thrown - simply ignore it...
        if (
          !(window as { DOMException?: Error }).DOMException ||
          !(err instanceof DOMException)
        ) {
          console.info(err);
        }
      }
    })
    .filter((stylesheet) => !!stylesheet) as StylesheetDefinition[];
}

/**
 * Helper that imprints stylesheet definitions into a document object model, so
 * that the css text is inlined before submitting a request to the backend.
 */
function imprintStylesheets(
  doc: Document,
  stylesheetDefs: StylesheetDefinition[],
): void {
  for (const stylesheetDef of stylesheetDefs) {
    // Create a reference to the stylesheet owner node
    const ownerNode = doc.querySelector(`[gexport-id="${stylesheetDef.id}"]`);

    // Bail out if the owner node wasn't found!
    if (!ownerNode || !ownerNode.parentNode) {
      return;
    }

    // Create new style-object, which we'll inject the extracted css
    // into
    const stylesheet = doc.createElement("style");

    // Explicitly specify type
    stylesheet.type = stylesheetDef.cssType;

    // Inject the extracted css from the dynamic stylesheet as a static
    // value
    stylesheet.appendChild(doc.createTextNode(stylesheetDef.cssText));

    // Replace the original stylesheet with the static copy
    ownerNode.parentNode.replaceChild(stylesheet, ownerNode);
  }
}

/**
 * Helper that loops through and resets all internal gexport-id tags from the
 * document, once we no longer need access to those.
 */
function resetStylesheets(doc: Document): void {
  Array.from(doc.querySelectorAll("[gexport-id]")).forEach((stylesheet) => {
    stylesheet.removeAttribute("gexport-id");
  });
}

/** Helper that determines if a given stylesheet is actually a CSSStyleSheet */
function isCssStylesheet(stylesheet: StyleSheet): stylesheet is CSSStyleSheet {
  return !!(stylesheet.ownerNode && "cssRules" in stylesheet);
}

/** Helper that extracts the static css content of a stylesheet */
function extractCssText(
  stylesheet: CSSStyleSheet | null,
  doc: Document,
): string {
  if (!stylesheet) {
    return "";
  }

  let cssText = "";
  const resolveUrl = createUrlResolver(stylesheet.href || doc.location.href);

  // Loop through and extract css from all rules within the stylesheet
  Array.from(stylesheet.cssRules).forEach((cssRule) => {
    // Handle everything but import rules by simply injecting the value into
    // our CSS text
    if (cssRule.type !== cssRule.IMPORT_RULE) {
      cssText += `${cssRule.cssText}\n`;

      // Import rules we need to attempt to handle by extracting their
      // specific css and injecting it in the output
    } else {
      const importRule = cssRule as CSSImportRule;

      try {
        // Start by extracting the CSS text from the imported stylesheet
        let importedCssText = extractCssText(importRule.styleSheet, doc);

        // Bail out, if no css text was actually contained within this
        // imported stylesheet
        if (!importedCssText) {
          return;
        }

        // Determine if the css media should be wrapped in a media
        // query?
        if (importRule.media.length > 0) {
          importedCssText = `@media ${importRule.media.toString()} { ${importedCssText} }`;
        }

        // Inject the imported CSS text
        cssText += `${importedCssText}\n`;
      } catch (err) {
        // If an error occurs here, it usually just means that some kind
        // of security error was thrown - simply ignore it and add the
        // original import statement to allow Headless Chrome to try and
        // resolve the reference later on
        cssText += `${cssRule.cssText}\n`;

        // Propagate errors to console...
        if (
          !(window as { DOMException?: Error }).DOMException ||
          !(err instanceof DOMException)
        ) {
          console.error(err);
        }
      }
    }
  });

  // Loop through and inject the baseUrl into all relative urls!
  cssText = cssText.replace(
    /url\((["']?)([^)"']+)(["']?)\)/gi,
    (match, q1: string, url: string, q2: string) => {
      // Ignore data urls
      if (url.substr(0, 5) === "data:") {
        return match;
      }

      // ... Otherwise resolve the url
      return `url(${q1}${resolveUrl(url)}${q2})`;
    },
  );

  return cssText;
}

/** Helper that resolves a url relatively to a given base url */
function createUrlResolver(baseUrl: string): (url: string) => string {
  // Create a document implementation, in which we can inject our custom base
  // url!
  const doc = document.implementation.createHTMLDocument();

  // Inject the given base url into the document, so that links will resolve
  // relatively to this address later on
  const base = doc.createElement("base");

  base.href = baseUrl;

  doc.head.appendChild(base);

  // Return a resolver, which will work on the base url specified above!
  return (url: string) => {
    // Create an anchor tag, which will resolve the given url relatively to the
    // base url that we injected above
    const a = doc.createElement("a");

    a.href = url;

    // Return the resolved url
    return a.href;
  };
}

/**
 * Helper that removes all elements from the given document, that are not
 * supported when the ExportService is converting an html input.
 */
function removeUnsupportedElements(doc: Document): void {
  // Some kinds of elements are completely unsupported
  Array.from(doc.querySelectorAll("script, iframe, frame, audio")).forEach(
    (unsupportedElement) => {
      // Skip nodes that aren't properly mounted (this cannot happen
      // technically speaking, but TypeScript is happier this way)
      if (!unsupportedElement.parentNode) {
        return;
      }

      unsupportedElement.parentNode.removeChild(unsupportedElement);
    },
  );
}

/**
 * Helper that'll imprint the values entered into input fields in the original
 * window into the html generated in the cloned document.
 */
function imprintInputValues(clonedDoc: Document, originalWin: Window): void {
  const originalInputs = originalWin.document.getElementsByTagName("input");
  const clonedInputs = clonedDoc.getElementsByTagName("input");

  for (let i = originalInputs.length; --i >= 0; ) {
    const originalInput = originalInputs[i];

    if (originalInput) {
      clonedInputs[i]?.setAttribute("value", originalInput.value);
    }
  }
}

/** Helper that rasterizes all canvasses in the document into static images */
function rasterizeCanvasses(clonedDoc: Document, originalWin: Window): void {
  const originalCanvasses = originalWin.document.getElementsByTagName("canvas");
  const clonedCanvasses = clonedDoc.getElementsByTagName("canvas");

  for (let i = originalCanvasses.length; --i >= 0; ) {
    const originalCanvas = originalCanvasses[i];
    const clonedCanvas = clonedCanvasses[i];

    // Skip canvasses that aren't properly mounted (this cannot happen
    // technically speaking, but TypeScript is happier this way)
    if (!originalCanvas || !clonedCanvas?.parentNode) {
      continue;
    }

    // Create an image tag, that we'll use to inject a static copy of the
    // canvas
    const img = clonedDoc.createElement("img");

    // Inject the rendered content of the canvas as a rasterized image
    img.src = originalCanvas.toDataURL();

    // Make sure that className and id is added to the image to match that
    // of the canvas tag
    img.id = originalCanvas.id;
    img.className = originalCanvas.className;

    // Clone any inline css onto the image
    img.style.cssText = originalCanvas.style.cssText;

    // Fixate size of the image to match that of the original canvas
    img.width = originalCanvas.offsetWidth;
    img.height = originalCanvas.offsetHeight;

    // Replace the cloned canvas with the rasterized image
    clonedCanvas.parentNode.replaceChild(img, clonedCanvas);
  }
}

/** Helper that extracts the document type from the window */
function extractDocType(win: Window): string {
  const doctype = win.document.doctype;

  // Add doctype to HTML
  if (doctype) {
    const publicId = doctype.publicId ? `"${doctype.publicId}"` : "";
    const systemId = doctype.systemId ? `"${doctype.systemId}"` : "";
    const publicType = publicId
      ? ` PUBLIC ${publicId}${systemId ? ` ${systemId}` : ""}`
      : "";
    const systemType = systemId && !publicId ? ` SYSTEM ${systemId}` : "";

    return `<!DOCTYPE ${doctype.name}${publicType}${systemType}>\n`;
  }

  return "";
}
