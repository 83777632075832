import { config } from "../config.js";
import { extractHtmlFromWindow } from "../utils/dom.extract-html-from-window.js";
import type { ResponseStream } from "../utils/response-stream.js";
import { runExportService } from "../utils/response-stream.js";

/** Specification of response from the convert process */
export type ConvertProcessResponse = {
  url: string;
};

/** Specification of arguments when converting a file */
export type ConvertArgsBase = {
  /**
   * Optionally specify a base url which all relative urls within the input
   * will be resolved relatively to. If nothing is given, then the currently
   * active url will be supplied as a base url.
   */
  baseUrl?: string;

  /**
   * Optionally specify how long the generated output should be cached for,
   * given in milliseconds.
   */
  cache?: number;

  /**
   * Optionally specify a name for the generated file. If nothing is supplied,
   * then an autogenerated name will be given.
   */
  fileName?: string;

  /** Specify which format to convert the input to */
  format: "pdf" | "jpg" | "docx";

  /**
   * Either supply an html input string, which will be converted to the
   * desired format, or pass a reference to `window` or an element in order to
   * forward a dump of the currently rendered html.
   */
  input: string | Window | Element;

  /**
   * Optionally specify which preset to use when converting the input to the
   * desired format. This has no effect for `docx`, but allows you to choose
   * between a variety of different output settings for both `jpg` and `pdf`.
   *
   * For further details about available presets, please contact Gyldendal or
   * Appear.
   */
  preset?: string;

  /**
   * When you're dumping the rendered html of `window` to the ExportService,
   * you might want to transform parts of the html before generating the
   * output file. Here you can optionally supply a transformer, which takes
   * the dumped html and returns the transformed html.
   */
  transformInput?(input: string): string;
};

/** Docx only supports the basic convert arguments */
export type DocxConvertArgs = ConvertArgsBase & {
  format: "docx";

  /**
   * data provided to the Docxtemplater render pass, which will be injected into
   * the header/footer part of the document
   */
  templateData: {
    product?: {
      title: string;
      subject?: string;
      gradesLabel?: string;
      classLabel?: string;
    };
    course?: {
      title: string;
      authors?: string;
    };
    user?: {
      name: string;
      institution?: string;
    };
    publisher?: string;
    date?: string;
  };
};

/**
 * Jpg supports the basic convert arguments as well as an optional aspect ratio
 * for the generated screenshot.
 */
export type JpgConvertArgs = ConvertArgsBase & {
  format: "jpg";

  /**
   * Optionally specify if you'd prefer taking the screenshot in high or
   * standard definition (ie. a pixel ratio of 1 or 2) - default is sd.
   */
  preset?: "hd" | "sd";

  /**
   * Specifies the desired aspect ratio of the generated image as
   * height-to-width (ie. if given, the height will be calculated as width
   * x this value).
   */
  aspectRatio?: number;

  /**
   * If enabled, then the input will be treated as an embedded iframe - this
   * might lead to better screenshots, if screenshots are used as placeholders
   * for iframes on a page.
   */
  embed?: boolean;

  /**
   * A number of seconds that the page is allowed to post-process itself in
   * order to become fully ready for rendering.
   */
  postProcessDelay?: number;
};

/** Pdf only supports the basic convert arguments */
export type PdfConvertArgs = ConvertArgsBase & {
  format: "pdf";

  /**
   * Optionally specify a preset to apply to the paper when printing to pdf
   * to determine orientation, margins etc. - default is portrait.
   */
  preset?:
    | "full-paper.landscape"
    | "full-paper.portrait"
    | "i-ark.landscape"
    | "i-ark.portrait"
    | "i-ark2.landscape"
    | "i-ark2.portrait"
    | "i-log"
    | "landscape.full"
    | "landscape"
    | "opgavekomplekset"
    | "pm2.landscape"
    | "pm2.portrait"
    | "portrait.full"
    | "portrait";

  /**
   * If enabled, then the input will be treated as an embedded iframe - this
   * might lead to better screenshots, if screenshots are used as placeholders
   * for iframes on a page.
   */
  embed?: boolean;

  /**
   * Print the given html template into the header of all pages when creating
   * the pdf (note that this setting only works for selected presets).
   */
  headerTemplate?: string;

  /**
   * Print the given html template into the footer of all pages when creating
   * the pdf (note that this setting only works for selected presets).
   */
  footerTemplate?: string;

  /**
   * A number of seconds that the page is allowed to post-process itself in
   * order to become fully ready for rendering.
   */
  postProcessDelay?: number;
};

/** Combined specification of all supported convert args */
export type ConvertArgs = DocxConvertArgs | JpgConvertArgs | PdfConvertArgs;

/**
 * Helper that'll perform a request to the ExportService backend that requests
 * it to converts the given input into the requested format.
 */
export function convert({
  input,
  cache,
  transformInput,
  ...args
}: ConvertArgs): ResponseStream<ConvertProcessResponse> {
  const extractedInput = (() => {
    if (typeof input === "string") {
      return input;
    }
    args.baseUrl =
      input instanceof Window ? input.location.href : window.location.href;

    return input instanceof Window
      ? extractHtmlFromWindow(input)
      : extractHtmlFromWindow(window, input);
  })();

  const transformedInput = transformInput?.(extractedInput) ?? extractedInput;

  return runExportService(
    new Request(`${config.baseUrl}/convert/${args.format}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-Security-Method": !config.baseUrl.includes("localhost")
          ? "amazon.export.gyldendal.dk"
          : "",
      },
      body: JSON.stringify({
        ...args,
        expiresInMs: cache,
        input: transformedInput,
      }),
    }),
  );
}
